import React from 'react';
import PropTypes from 'prop-types';

import SlickSlider from 'react-slick';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';

import './styles/slick.css';
import './styles/slick-theme.css';

const Slider = ({ children, color, className, settings, ...props }) => {
  const isLgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    ...settings,
  };

  return (
    <Box px={isLgUp ? 32 : 0} className={className} {...props}>
      <SlickSlider {...sliderSettings}>{children}</SlickSlider>
    </Box>
  );
};

Slider.defaultProps = {
  className: undefined,
  color: 'default',
  settings: {},
};

Slider.propTypes = {
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'inherit', 'invert']),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  settings: PropTypes.object,
};

export { Slider };
