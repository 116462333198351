import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import {theme} from 'theme';
import {makeStyles} from '@material-ui/styles';
import clsx from "clsx";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    hidden: {
        display: 'none'
    },
    name: {
        fontWeight: 700,
        lineHeight: "24px",
        fontSize: '1.125rem',
        marginBottom: theme.spacing(3),
    },
    phone: {
        marginBottom: theme.spacing(6),
        lineHeight: "24px",
        fontSize: '1rem',
    },
    email: {
        marginBottom: theme.spacing(2),
        lineHeight: "24px",
        fontSize: '1rem'
    },
    image: {
        borderRadius: 24,
        marginBottom: theme.spacing(8),
        objectFit: 'cover',
        /*opacity: 0.9,*/
    },
    block: {
        display: 'block',
    },
    teamMember: {
        paddingBottom: theme.spacing(10),
        width: '271px',
        minHeight: '619px',
        boxShadow: '10px 10px 24px rgba(0, 0, 0, 0.15)',
        borderRadius: '24px',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(3),
            '&:before': {
                position: "absolute",
                content: '""',
                borderRadius: "24px",
                height: "619px",
                width: "130px",
                left: "150px",
                zIndex: -1,
                backgroundColor: "#fff"
            }
        },
    },
    pr: {
        marginRight: theme.spacing(6.25),
        [theme.breakpoints.down('md')]: {
            marginRight: 0,
        }
    },
    pt2: {
        paddingTop: theme.spacing(2)
    },
    linkReadMore: {
        cursor: 'pointer',
        color: theme.palette.common.secondaryBlue,
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'none',
        },
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '24px',
        verticalAlign: 'bottom'
    },
    linkSeeLess: {
        transform: 'rotateY(180deg)'
    },
    extraInfo: {
        minHeight: '552px',
        transform: 'rotateY(180deg)',

    },
    extraTextTop: {
        minHeight: '144px',
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(8),
        borderTopLeftRadius: 24,
        borderTopRightRadius: 24,
        whiteSpace: 'pre-line'
    },
    extraText: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        textAlign: 'initial',
        whiteSpace: 'pre-line'
    },
    rotate: {
        transform: 'rotateY(180deg)'
    },
    flipCardInner: {
        transition: 'transform 0.6s',
        transformStyle: 'preserve-3d',
    }
}));

const TeamItemCard = ({image, name, text, textShort, email, phone, width, index}) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const [showMoreInfo, setShowMoreInfo] = useState(false);

    const toggleShowMoreInfo = async () => {
        setShowMoreInfo(!showMoreInfo);
    };

    return (
        <Box
            className={clsx(classes.teamMember, index !== 3 && classes.pr, showMoreInfo && classes.rotate, classes.flipCardInner)}
            bgcolor={theme.palette.common.white}
            borderRadius={24}
            position="relative"
            display="block"
        >
            <Box className={clsx(showMoreInfo && classes.hidden, !showMoreInfo && 'fade-in-image')}>
                <img
                    alt={`${name}`}
                    src={`/images/team/${image}`}
                    width={272}
                    height={407}
                    className={classes.image}
                />
                <Typography variant="h6" className={classes.name}>
                    {name}
                </Typography>
                {email && (
                    <Typography
                        variant="body2"
                        className={classes.email}
                        component="a"
                        href={`mailto:${email}`}
                        color="textPrimary"
                    >
                        {email}
                    </Typography>
                )}
                <Typography variant="body2" className={classes.phone}>
                    {phone}
                </Typography>
            </Box>
            <Box className={clsx(!showMoreInfo && classes.hidden, classes.extraInfo)}>
                <Box className={classes.extraTextTop} bgcolor={"#CCDAE8"}>
                    <Typography variant="h5">
                        {name}
                    </Typography>
                    <Typography variant="body2" align="center" className={classes.pt2}>
                        {t(textShort)}
                    </Typography>
                </Box>
                <Typography variant="body1" className={classes.extraText}>
                    {t(text)}
                </Typography>
            </Box>
            <Typography variant="h5" align="center"
                        className={clsx(classes.linkReadMore, showMoreInfo && classes.linkSeeLess)}
                        onClick={toggleShowMoreInfo}>
                {!showMoreInfo ? t('READ_MORE_INFO') : t('READ_LESS_INFO')}
            </Typography>
        </Box>
    );
};

TeamItemCard.defaultProps = {
    email: undefined,
    width: undefined,
};

TeamItemCard.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    textShort: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone: PropTypes.string,
    width: PropTypes.number,
    index: PropTypes.number
};

export {TeamItemCard};
