import React from 'react';

const TimesIcon = ({ width = 14, height = 16, variant, margin, ...restProps }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.53125 0.53125L0.53125 8.53125C0.40625 8.66667 0.34375 8.82292 0.34375 9C0.34375 9.17708 0.40625 9.33333 0.53125 9.46875C0.666667 9.59375 0.822917 9.65625 1 9.65625C1.17708 9.65625 1.33333 9.59375 1.46875 9.46875L9.46875 1.46875C9.5625 1.38542 9.625 1.28646 9.65625 1.17188C9.6875 1.05729 9.6875 0.942708 9.65625 0.828125C9.625 0.703125 9.56771 0.598958 9.48438 0.515625C9.40104 0.432292 9.29688 0.375 9.17188 0.34375C9.05729 0.3125 8.94271 0.3125 8.82812 0.34375C8.71354 0.375 8.61458 0.4375 8.53125 0.53125ZM0.53125 1.46875L8.53125 9.46875C8.66667 9.59375 8.82292 9.65625 9 9.65625C9.17708 9.65625 9.32812 9.59375 9.45312 9.46875C9.58854 9.33333 9.65625 9.17708 9.65625 9C9.65625 8.82292 9.59375 8.66667 9.46875 8.53125L1.46875 0.53125C1.33333 0.40625 1.17708 0.34375 1 0.34375C0.822917 0.34375 0.666667 0.411458 0.53125 0.546875C0.40625 0.671875 0.34375 0.822917 0.34375 1C0.34375 1.17708 0.40625 1.33333 0.53125 1.46875Z"
        fill="#333333"
      />
    </svg>
  );
};

export default TimesIcon;
