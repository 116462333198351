import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { generateKey } from 'shared/functions/generateKey';

import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';

import { Link, useHistory } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import { reactScrollTo } from 'shared/functions/reactScrollTo';
import { languages } from 'shared/constants/languages';

import { LanguageSwitcher, Logo, HamburgerIcon } from 'components';
import clsx from 'clsx';
import { NavTabs } from './NavTabs/NavTabs';

const useStyles = makeStyles((theme) => ({
  root: {

  },
  container: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0),
    },
  },
  logo: {
    cursor: 'pointer',
  },
  rightSection: {
    marginLeft: 'auto',
  },
  offset: {
    minHeight: 80,
  },
  toolbar: {
    padding: theme.spacing(0, 10),
    minHeight: 80,
    display: 'flex',
    justifyContent: 'space-start',
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0, 5),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 10),
    },
  },
  colorRevert: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
  },
  iconButton: {
    color: theme.palette.common.white,
    padding: theme.spacing(0),
    marginRight: theme.spacing(6),
    borderRadius: 0,
  },
  requestDemoButton: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0),
      textDecoration: 'underline',
      '&:hover': {
        background: 'none',
      },
    },
  },
  list: {
    marginTop: theme.spacing(56),
  },
  listItem: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(0),
    color: theme.palette.common.primaryBlue,
    lineHeight: "22px",
    '& a':{
      color: '#033265 !important',
    }
  },
  bold: {
    fontWeight: 700,
  },
  drawer: {
    width: '100%',
    alignItems: 'flex-start',
    padding: theme.spacing(5, 6),
    backgroundColor: '#E6EDF4'
  },
  link: {
    textDecoration: 'none',
  },
  closeIcon: {
    marginBottom: theme.spacing(4),
  },
  closeButton:{
    textAlign: 'right',
    width: '100%'
  },
  leftArrowImage: {
    marginRight: theme.spacing(2),
  },
}));

const NavBar = ({ id, tabs, activeTab, activeTabChanged, languageSwitcher, useScrollLink }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const showNavLinks = useMediaQuery((theme) => theme.breakpoints.up('amazonFire'));
  const isLgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const [toggleDrawer, setToggleDrawer] = useState(false);

  const handleDrawerClick = () => setToggleDrawer(!toggleDrawer);
  const closeDrawer = () => setToggleDrawer(false);

  const goToTop = () => (useScrollLink ? reactScrollTo('root') : history.push('/'));

  const onLanguageChange = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <>
      <nav id={id}>
        <AppBar elevation={0} color="primary" className={classes.root}>
          <Container maxWidth="xl" className={classes.container}>
            <Toolbar className={classes.toolbar}>
              <Box display="flex" alignItems="center">
                {!showNavLinks && tabs && (
                  <>
                    <IconButton
                      type="submit"
                      className={classes.iconButton}
                      onClick={handleDrawerClick}
                    >
                      <HamburgerIcon />
                    </IconButton>
                    <Drawer
                      anchor="left"
                      open={toggleDrawer}
                      onClose={handleDrawerClick}
                      classes={{ root: classes.drawer }}
                      PaperProps={{ className: classes.drawer }}
                    >
                      <List component="nav" className={classes.list} aria-labelledby="navbar-list-subheader">
                        {tabs.map((tab, index) => {
                          const { label, link } = tab;
                          return (
                            <ListItem
                              key={generateKey(index, link)}
                              button
                              className={clsx(
                                classes.listItem,
                                activeTab && label === activeTab.label && classes.bold,
                              )}
                            >
                              {useScrollLink ? (
                                <ScrollLink
                                  to={link}
                                  spy
                                  smooth
                                  offset={0}
                                  duration={500}
                                  onClick={closeDrawer}
                                  onSetActive={() => activeTabChanged(tab, true)}
                                  onSetInactive={() => activeTabChanged(tab, false)}
                                >
                                  {t(label)}
                                </ScrollLink>
                              ) : (
                                <Link to={link} onClick={closeDrawer} className={classes.link}>
                                  {t(label)}
                                </Link>
                              )}
                            </ListItem>
                          );
                        })}
                      </List>
                      <Box className={classes.closeButton}>
                      <img
                          src="/images/close.svg"
                          alt="close"
                          className={classes.closeIcon}
                          onClick={handleDrawerClick}
                          onKeyDown={handleDrawerClick}
                          role="presentation"
                      />
                      </Box>
                    </Drawer>
                  </>
                )}
                <Logo small={false} onClick={goToTop} className={classes.logo} />
              </Box>
              {showNavLinks && tabs && (
                <NavTabs
                  tabs={tabs}
                  activeTab={activeTab}
                  activeTabChanged={activeTabChanged}
                  useScrollLink={useScrollLink}
                />
              )}
              {!tabs && (
                <>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={isLgUp ? 'flex-start' : 'flex-end'}
                    maxWidth={896}
                    flexGrow={1}
                  >
                    <img
                      className={classes.leftArrowImage}
                      src="/images/left-arrow.svg"
                      alt="Go to Financial Vision homepage"
                    />
                  </Box>
                  {/* empty placeholder with size the same as logo, just for flex centering of the link container */}
                  {isLgUp && <Box width={120} />}
                </>
              )}
              {languageSwitcher && (
                <LanguageSwitcher
                  languages={languages}
                  selectedLanguage={i18n.language}
                  onLanguageChange={onLanguageChange}
                />
              )}
            </Toolbar>
          </Container>
        </AppBar>
      </nav>
      <div className={classes.offset} />
    </>
  );
};

NavBar.defaultProps = {
  activeTab: undefined,
  useScrollLink: true,
  languageSwitcher: false,
  activeTabChanged: () => null,
  tabs: undefined,
};

NavBar.propTypes = {
  id: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ),
  activeTab: PropTypes.shape({
    label: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
  languageSwitcher: PropTypes.bool,
  useScrollLink: PropTypes.bool,
  activeTabChanged: PropTypes.func,
};

export { NavBar };
