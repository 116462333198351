import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'theme';

const AccentVariant1 = ({ className, color, opacity }) => (
    <svg  className={className} width="463" height="288" viewBox="0 0 463 288" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd"  opacity={opacity} clipRule="evenodd" d="M183.629 478.294C207.043 478.331 230.861 474.605 254.281 466.719C369.553 427.904 431.534 302.991 392.719 187.719C353.904 72.4467 228.991 10.4659 113.719 49.2811C-1.55332 88.0963 -63.5341 213.009 -24.7189 328.281C-22.9059 333.665 -20.9052 338.933 -18.7264 344.08C-40.665 254.71 9.21124 161.929 98.0087 132.028C191.667 100.491 293.159 150.85 324.696 244.509C356.234 338.168 305.874 439.659 212.215 471.197C202.74 474.387 193.185 476.74 183.629 478.294Z" fill={color}/>
      <path fillRule="evenodd"  opacity={opacity} clipRule="evenodd" d="M158.783 484.348C178.398 484.449 198.364 481.366 217.993 474.756C313.535 442.585 364.907 339.053 332.735 243.511C300.564 147.97 197.032 96.5979 101.49 128.769C5.94862 160.941 -45.4232 264.472 -13.2518 360.014C-10.0564 369.504 -6.15711 378.557 -1.63155 387.137C-19.8388 318.819 17.9476 247.196 85.9028 224.314C156.668 200.486 233.352 238.535 257.18 309.301C281.009 380.066 242.959 456.749 172.194 480.578C167.742 482.077 163.267 483.331 158.783 484.348Z" fill={color}/>
    </svg>
);

AccentVariant1.defaultProps = {
  className: undefined,
  color: theme.palette.common.greyLighter,
  opacity: 1,
};

AccentVariant1.propTypes = {
  className: PropTypes.string,
  opacity: PropTypes.number,
  color: PropTypes.string,
};

export { AccentVariant1 };
