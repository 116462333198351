import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'theme';

const AccentVariant4 = ({ className, color, opacity }) => (
  <svg
    className={className}
    width="657"
    height="304"
    viewBox="0 0 657 304"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M225.276 295.857L340.842 160.143C346.217 154.714 346.217 146.571 343.529 141.143L289.778 10.8571C287.09 2.71429 279.028 0 273.652 0H69.3968C55.9589 0 47.8961 13.5714 53.2713 27.1429L101.648 141.143C104.335 146.571 104.335 154.714 98.9601 160.143L4.89494 271.429C-5.85536 285 2.20736 304 18.3328 304H214.526C217.213 301.286 222.589 298.571 225.276 295.857Z"
      fill={color}
      opacity={opacity}
    />
    <path
      d="M536.305 295.857L651.871 160.143C657.246 154.714 657.246 146.571 654.558 141.143L600.807 10.8571C598.119 2.71428 590.056 0 584.681 0H380.426C366.988 0 358.925 13.5714 364.3 27.1429L412.676 141.143C415.364 146.571 415.364 154.714 409.989 160.143L315.924 271.429C305.173 285 313.236 304 329.362 304H525.555C528.242 301.286 533.617 298.571 536.305 295.857Z"
      fill={color}
      opacity={opacity}
    />
  </svg>
);

AccentVariant4.defaultProps = {
  className: undefined,
  color: theme.palette.common.grayishViolet,
  opacity: 0.1,
};

AccentVariant4.propTypes = {
  className: PropTypes.string,
  opacity: PropTypes.number,
  color: PropTypes.string,
};

export { AccentVariant4 };
