import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'theme';

import { makeStyles } from '@material-ui/styles';
import MaterialSelect from '@material-ui/core/Select';

import { DropdownIcon } from 'components';

const useNativeStyles = makeStyles({
  root: {
    color: theme.palette.common.white,
  },
  outlined: {
    '& ~ fieldset': {
      borderColor: `${theme.palette.common.mostlyDesaturatedDarkViolet} !important`,
    },
    '&:hover ~ fieldset': {
      borderColor: `${theme.palette.common.mostlyDesaturatedDarkViolet} !important`,
    },
  },
  icon: {
    fill: theme.palette.common.white,
  },
});

const useStyles = makeStyles({
  dropDownIcon: {
    marginRight: theme.spacing(3.5),
  },
});

const Select = ({ variant, ...props }) => {
  const nativeClasses = useNativeStyles();
  const classes = useStyles();

  return (
    <MaterialSelect
      classes={nativeClasses}
      IconComponent={() => <DropdownIcon variant={variant} className={classes.dropDownIcon} />}
      {...props}
    />
  );
};

Select.defaultProps = {
  variant: 'light',
};

Select.propTypes = {
  variant: PropTypes.oneOf(['light', 'dark']),
};

export { Select };
