import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'theme';

const DropdownIcon = ({ variant, ...restProps }) => {
  let strokeColor = theme.palette.common.white;
  if (variant === 'dark') {
    strokeColor = theme.palette.common.grayishViolet;
  }

  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M1 1L7 7L13 1"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

DropdownIcon.defaultProps = {
  variant: 'light',
};

DropdownIcon.propTypes = {
  variant: PropTypes.oneOf(['light', 'dark']),
};

export { DropdownIcon };
