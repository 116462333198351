import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import debounce from 'lodash/debounce';
import Headroom from 'react-headroom';

import { NavBar } from 'components';

import { navTabs } from 'shared/constants/navTabs';
import { reactScrollTo } from 'shared/functions/reactScrollTo';
import { performInNextCycle } from 'shared/functions/performInNextCycle';
import { usePrevious } from 'shared/hooks/usePrevious';

import { theme } from 'theme';
import { routes } from 'routes';

const Header = ({ isRouteLoaded }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [visibleSection, setVisibleSection] = useState(undefined);
  const prevVisibleSection = usePrevious(visibleSection);

  const [activeTab, setActiveTab] = useState(undefined);
  const [isMainView, setIsMainView] = useState(false);

  useEffect(() => {
    const tab = navTabs.find(({ link }) => link === pathname.substring(1));
    setActiveTab(tab);

    const mainRoute = routes.find(({ name }) => name === 'main');
    setIsMainView(mainRoute.path.length && mainRoute.path.includes(pathname));
  }, [pathname]);

  const activeTabChanged = ({ link: section }, isVisible) => {
    if (isVisible) {
      setVisibleSection(() => section);
    } else {
      setVisibleSection((currentVisibleSection) =>
        currentVisibleSection === section ? undefined : currentVisibleSection,
      );
    }
  };

  const updateHistory = useCallback(
    debounce((prevVisibleElement, visibleElement) => {
      if (visibleElement) {
        history.replace(`/${visibleElement}`);
      } else if (prevVisibleElement) {
        history.replace('/');
      }
    }, 200),
    [],
  );

  useEffect(() => {
    updateHistory(prevVisibleSection, visibleSection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleSection]);

  useEffect(() => {
    if (pathname && isRouteLoaded) {
      const path = pathname.slice(1);
      if (path && visibleSection !== path) {
        performInNextCycle(() => reactScrollTo(path, 0));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isRouteLoaded]);

  return (
    <Headroom
      style={{ zIndex: 100 }}
      wrapperStyle={{ backgroundColor: theme.palette.primary.main }}
    >
      <NavBar
        id="back-to-top-anchor"
        activeTab={activeTab}
        activeTabChanged={activeTabChanged}
        tabs={navTabs}
        useScrollLink={isMainView}
        languageSwitcher={false}
      />
    </Headroom>
  );
};

export { Header };
