export default {
  SERVICES: 'Teenused',
  ABOUT_US: 'Meist',
  PRICING: 'Paketid',
  OUR_PEOPLE: 'Spetsialistid',
  WORK_PROCESS: 'Tööprotsess',
  OUR_CLIENTS: 'Meie kliendid',
  CONTACT: 'Kontakt',
  REQUEST_A_DEMO_HERO:'Küsi pakkumist',
  REQUEST_A_DEMO: 'Võta meiega ühendust',
  REQUEST_A_DEMO_DESC:
    "Tärniga tähistatud väljad on kohustuslikud.",
  HERO_TITLE_COMPANY: 'Financial Vision',
  HERO_TITLE_LINE1: 'on Sinu partner raamatupidamises ',
  HERO_DESC:
    'Pakume nii raamatupidamisteenust täisteenusena kui ka palgaarvestust, konsultatsiooni või näiteks pearaamatupidaja teenust eraldiseisvalt.',
  SERVICES_DESC: 'Teenuseid võime komplekteerida just Sinu ettevõtte vajadustest lähtuvalt. \n Võta meiega ühendust ning leiame parima teenuste komplekti!',
  ACCOUNTING_FULL_SOLUTION: 'Raamatupidamis-\n' +
      'teenuse terviklahendus',
  ACCOUNTING_FULL_SOLUTION_TITLE: 'Raamatupidamisteenuse terviklahendus',
  ACCOUNTING_FULL_SOLUTION_TITLE_SM: 'Raamatupidamisteenuse terviklahendus',
  ACCOUNTING_FULL_SOLUTION_DESC:
    'Pakume terviklahendust lähtudes Sinu ettevõtte spetsiifikast. Teenus hõlmab täielikku raamatupidamisarvestust alates algdokumentide sisestamisest kuni aastaaruandeni välja.',
  ANNUAL_REPORTING: 'Majandusaasta \n aruannete koostamine',
  ANNUAL_REPORTING_TITLE: 'Majandusaasta aruannete koostamine',
  ANNUAL_REPORTING_TITLE_SM: 'Majandusaasta aruannete koostamine',
  ANNUAL_REPORTING_DESC:
    'Meie koostatud majandusaasta aruande puhul saad alati kindel olla, et aruande koostamisel on arvestatud kõikide raamatupidamise hea tava nõuetega ning esitatav aruanne on arvestuspõhimõtetega kooskõlas.',
  CONSULTATION: 'Konsultatsioonid ja \n nõustamine',
  CONSULTATION_TITLE: 'Konsultatsioonid ja nõustamine',
  CONSULTATION_TITLE_SM: 'Konsultatsioonid ja nõustamine',
  CONSULTATION_DESC:
    'Nõustame finants- ja maksualastes küsimustes. Meie asjatundlik nõu aitab analüüsida tehingutega kaasnevaid maksutagajärgi ning vastu võtta paremaid otsuseid lähtudes ettevõtte ärivajadustest.',
  CHIEF_ACCOUNTANT: 'Pearaamatupidaja / \n Finantsjuhi teenus',
  CHIEF_ACCOUNTANT_TITLE: 'Pearaamatupidaja / Finantsjuhi teenus',
  CHIEF_ACCOUNTANT_TITLE_SM: 'Pearaamatupidaja / Finantsjuhi teenus',
  CHIEF_ACCOUNTANT_DESC:'Teeme koostööd Sinu ettevõtte raamatupidamisassistendiga. Meie hoiame silma peal ettevõtte igapäevasel raamatupidamisel, koostame finantsaruandeid ning vastutame auditeerimisprotsessi koordineerimise eest.',
  READ_MORE: 'Loe rohkem',
  READ_MORE_INFO: 'Rohkem infot',
  READ_LESS_INFO: 'Vähem infot',
  ABOUT_US_TEXT_1:'Financial Vision OÜ sai alguse 2016. aastal, kui loobusin palgatööst ning alustasin särasilmil millegagi, mis on päris enda jaoks. Omasin tollal juba aastatepikkust raamatupidamisalast kogemust ning leidsin, et kui midagi tõeliselt hingega teha, tuleb see kindlasti väga hästi välja.',
  ABOUT_US_TEXT_2:'Tänaseks oleme kasvanud kolmeliikmeliseks ning hoiame igakuiselt korras rohkem kui 50 kliendi raamatupidamise.',
  ABOUT_US_TEXT_3:'Financial Vision OÜ eesmärgiks on koostöös kliendiga automatiseerida ning kaasajastada raamatupidamisprotsesse, mille tulemusel hoiame kokku mõlema osapoole väärtuslikku aega. Meie teenus on kõrge kvaliteediga ning toetab maksimaalselt kliendi äritegevust.',
  ABOUT_US_CEO:'– Elen Nurk, Financial Vision OÜ tegevjuht',
  PRICING_TITLE: 'Vali endale sobiv pakett',
  PRICING_TITLE_LINE1: 'Sinu ettevõtte raamatupidamise korrashoidmise hind sõltub kokkulepitud teenuste sisust, sagedusest  ning raamatupidamises tehtavate toimingute arvust. Paketid väike, keskmine ja suur annavad ülevaate, milliseks võiks kuutasu kujuneda.',
  PRICING_TITLE_LINE2: 'Soovid lähemalt uurida? Küsi kindlasti meilt konkreetsemat pakkumist lähtuvalt Sinu ettevõtte vajadustest.',
  PRICING_BLOCK_TITLE_1:'ÄRIPAKETT VÄIKSELE',
  PRICING_BLOCK_TITLE_2:'ÄRIPAKETT KESKMISELE',
  PRICING_BLOCK_TITLE_3:'ÄRIPAKETT SUURELE',
  PRICING_BLOCK_1_LINE_1:'Müügiarveid kuni 10 tk/kuus',
  PRICING_BLOCK_1_LINE_2:'Ostuarveid kuni 10 tk/kuus',
  PRICING_BLOCK_1_LINE_3:'1 töötaja',
  PRICING_BLOCK_1_LINE_4:'Deklaratsioonide esitamine',
  PRICING_BLOCK_2_LINE_1:'Müügiarveid 11-50 tk/kuus',
  PRICING_BLOCK_2_LINE_2:'Ostuarveid 11-50 tk/kuus',
  PRICING_BLOCK_2_LINE_3:'1-5 töötajat',
  PRICING_BLOCK_2_LINE_4:'Deklaratsioonide esitamine',
  PRICING_BLOCK_3_LINE_1:'Müügiarveid üle 50 tk/kuus',
  PRICING_BLOCK_3_LINE_2:'Ostuarveid üle 50 tk/kuus',
  PRICING_BLOCK_3_LINE_3:'Töötajaid üle 5',
  PRICING_BLOCK_3_LINE_4:'Deklaratsioonide esitamine',
  MONTH_PLUS_VAT: 'kuu+KM%',
  OUR_TEAM: 'Meie spetsialistid',
  ELEN_SHORT: 'Pearaamatupidaja \n kutsekvalifikatsiooni tase 6',
  ELEN_TEXT: 'Oma tugevusteks pean pidevat enesearendamist, pikaajalist, mitmekesist ning vastutusrikast töökogemust raamatupidamise valdkonnas, häid teadmisi maksudest ning oskust orienteeruda valdkonna seadustes.',
  MARGERY_SHORT: 'Pearaamatupidaja \n kutsekvalifikatsiooni tase 6',
  MARGERY_TEXT: 'Pean enda tugevamateks külgedeks töökust, kiiret reageerimisvõimet ja loogilist mõtlemist. \n' +
      '\n' +
      'Talun hea meelega rutiini, samas olen alati avatud uuendustele, et pakkuda oma klientidele kiiret ning professionaalset teenust.',
  LAIVI_SHORT: 'Pearaamatupidaja \n kutsekvalifikatsiooni tase 5',
  LAIVI_TEXT: 'Uued väljakutsed mind ei heiduta, tööd tehes olen maksimalist. Ettevõtmisi teen südame ja hoolega. \n' +
      '\n' +
      'Mulle on oluline ka pisiasjadega tegeleda, millega teised võib-olla ei viitsiks. Olen tähelepanelik ja särtsakas. Ja midagi ei saa "juhuse hooleks" jätta, kõik asjad on läbi mõeldud ja põhjalikult analüüsitud.',
  KARMEN_SHORT: 'Pearaamatupidaja \n kutsekvalifikatsiooni tase 5',
  KARMEN_TEXT: 'Oma töö iseloomu silmas pidades proovin olla alati põhjalik, täpne ja tähtaegadest kinni pidada.\n \n See tähendab, et kui midagi luban või kokku lepin, saab see ka alati täidetud.\nOlen orienteeritud positiivse töökeskkonna loomisele.',
  CHRISTIN_SHORT: 'Raamatupidaja \n kutsekvalifikatsiooni tase 5',
  CHRISTIN_TEXT: 'Enda tugevuseks pean õppimishimu, kohusetunnet ja head ajaplaneerimist. Raamatupidamine meeldib mulle sellepärast, et saab koguaeg uusi teadmisi ja töö ei ole üksluine.',
  INVALID_NAME: 'Invalid name',
  INVALID_EMAIL: 'Invalid e-mail address',
  INVALID_PHONE: 'Invalid phone number',
  INVALID_MESSAGE: 'Message too long',
  CONTACT_US: 'Kontakt',
  WORKING_PROCESS: 'Meie tööprotsess',
  THIS_WEBSITE_USES_COOKIES: 'See koduleht kasutab küpsiseid',
  WE_USE_COOKIES:
    'Sellel veebilehel kasutatakse küpsiseid. Kasutamist jätkates nõustute küpsistega.',
  REJECT_COOKIES: 'Keela küpsised',
  ALLOW_COOKIES: 'Luba küpsised',
  PRIVACY_POLICY: 'Privacy policy',
  COOKIE_POLICY_FILE_NAME: 'Cookie policy',
  COOKIE_NOTICE: 'Küpsiste teade',
  NOT_FOUND_HEADING: 'Oh ei!',
  NOT_FOUND_DESC: 'Lehekülge ei leitud.',
  NOT_FOUND_DESC_2: 'Viga: 404',
  GO_TO_HOMEPAGE: 'Mine avalehele',
  FORM_SOMETHING_WENT_WRONG: 'Midagi läks valesti! Teadet ei saadetud. Palun proovi uuesti.',
  PHONE_NUMBER: 'Sinu telefoni nr',
  EMAIL: 'Sinu e-mail',
  MESSAGE: 'Kirja sisu',
  YOUR_NAME: 'Sinu nimi',
  GET_STARTED: 'Küsi pakkumist',
  NICE: 'Täname!',
  YOU_ARE_ALL_SIGNED_UP:'Sinu teade on edukalt saadetud.',
  WE_WILL_CONTACT_YOU:'Võtame sinuga varsti ühendust.'
};
