import React from 'react';
import Box from '@material-ui/core/Box';

const SpacerLine = ({ marginTop, marginBottom }) => <Box marginTop={marginTop} marginBottom={marginBottom}>
    <svg width="52" height="5" viewBox="0 0 52 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="2.5" y1="2.5" x2="49.5" y2="2.5" stroke="#DDA200" strokeWidth="5" strokeLinecap="round"/>
    </svg>
</Box>;

export { SpacerLine };
