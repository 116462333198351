import { createMuiTheme } from '@material-ui/core/styles';

const defaultTheme = createMuiTheme();

const white = '#ffffff';
const primaryBlue ="#033265";
const secondaryBlue ="#02488E";
const primaryYellow ="#DDA200";
const secondaryYellow ="#FBB603";
const greyDark ="#333333";
const greyMedium ="#BEBCBC";
const greyLight ="#D6D4D4";
const greyLighter ="#EAEAEA";
const alertRed ="#D72626";
const bodyBg = '#F3F4F6';
/*TODO: eemaldada*/
const veryDarkViolet = '#26084d';
const veryDarkDesaturatedViolet = '#513971';
const veryLightGray = '#f4f3f6';
const gray = '#858585';
const lightGrayishViolet = '#e9e6ed';
const lightGrayishVioletAlt = '#d4cedb';
const veryDarkGray = '#333333';
const veryDarkGrayAlt = '#3d3d3d';
const darkCyan = '#008094';
const mostlyDesaturatedDarkViolet = '#7d6b94';
const grayishViolet = '#a89cb8';
const strongViolet = '#6829b8';
const veryLightYellow = '#ffe178';
const vividYellow = '#DDA200';

const breakpoints = {
  iPhone5: 670,
  amazonFire: 1024,
  desktop: 1366,
};

export const theme = createMuiTheme({
  spacing: 4,
  palette: {
    primary: {
      contrastText: white,
      main: primaryBlue,
    },
    secondary: {
      contrastText: veryDarkViolet,
      main: vividYellow,
    },
    common: {
      white,
      veryDarkDesaturatedViolet,
      veryLightGray,
      grayishViolet,
      lightGrayishViolet,
      lightGrayishVioletAlt,
      darkCyan,
      bodyBg,
      mostlyDesaturatedDarkViolet,
      veryDarkViolet,
      veryDarkGray,
      veryDarkGrayAlt,
      strongViolet,
      veryLightYellow,
      vividYellow,
      gray,
      primaryBlue,
      secondaryBlue,
      primaryYellow,
      secondaryYellow,
      greyDark,
      greyMedium,
      greyLight,
      greyLighter,
      alertRed,
    },
    text: {
      primary: veryDarkGray,
      secondary: white,
    },
  },
  breakpoints: {
    values: {
      ...defaultTheme.breakpoints.values,
      ...breakpoints,
      xs: 0,
      sm: 600,
      iPhone5: 670,
      md: 960,
      lg: 1158,
      desktop: 1310,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    h1: {
      fontWeight: 800,
      fontSize: '3rem',
      lineHeight: '3.5rem',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '1.5rem',
        lineHeight: '2rem',
      },
    },
    h2: {
      fontWeight: 800,
      fontSize: '2.5rem',
      lineHeight: '4.25rem',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '1.5rem',
        lineHeight: '2rem',
      },
    },
    h3: {
      fontWeight: 800,
      fontSize: '1.5rem',
      lineHeight: '2.5rem',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '1.5rem',
        lineHeight: '2rem',
      },
    },
    h4: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: '1.5rem',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
      },
    },
    h5: {
      fontWeight: 700,
      fontSize: '1.125rem',
      lineHeight: '1.625rem',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '1rem',
        lineHeight: '1.375rem',
      },
    },
    h6: {
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: '1.375rem',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '0.875rem',
        lineHeight: '1.5rem',
      },
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
    subtitle1: {
      fontSize: '1rem',
      lineHeight: '1.375rem',
    },
    subtitle2: {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
    },
    MuiSelect: {
      variant: 'outlined',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: '0.875rem',
        textTransform: 'none',
        padding: defaultTheme.spacing(1.5, 3),
      },
      outlined: {
        padding: defaultTheme.spacing(1.5, 3),
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: '80px',
      },
    },
    MuiInputLabel: {
      root: {
        color: greyDark,
        textAlign:'left',
        marginBottom: defaultTheme.spacing(1),
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: defaultTheme.spacing(1.5, 1.75),
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 12,
      },
    },
    MuiSvgIcon: {
      root: {
        width: '2rem',
        height: '2rem',
      },
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          overflowX: 'hidden',
        },
        body: {
          overflowX: 'hidden',
          backgroundColor: bodyBg
        },
        a: {
          color: veryDarkViolet,
          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
    },
  },
});
