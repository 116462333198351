import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';

const useStyles = makeStyles({
    root: ({bgcolorHover, iconFillHover}) => ({
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: bgcolorHover,
            '& svg path': {
                fill: iconFillHover,
            },
        },
    }),
});

const IconWrapper = ({
                         onClick,
                         width = 32,
                         height = 32,
                         bgcolorHover,
                         iconFillHover,
                         className, restProps, children
                     }) => {
    const classes = useStyles({bgcolorHover, iconFillHover});
    return (
        <Box
            onClick={onClick}
            width={width}
            minWidth={width}
            height={height}
            minHeight={height}
            borderRadius="50%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={clsx(classes.root, className)}
            {...restProps}
        >
            {children}
        </Box>
    );
};

export default IconWrapper;
