import React from 'react';
import PropTypes from 'prop-types';

import { theme } from 'theme';

import { AccentVariant1, AccentVariant2, AccentVariant3, AccentVariant4 } from './AccentVariants';

const Accent = ({ className, color, opacity, variant }) => {
  const variants = {
    variant1: <AccentVariant1 className={className} color={color} opacity={opacity} />,
    variant2: <AccentVariant2 className={className} color={color} opacity={opacity} />,
    variant3: <AccentVariant3 className={className} color={color} opacity={opacity} />,
    variant4: <AccentVariant4 className={className} color={color} opacity={opacity} />,
  };

  return variant ? variants[variant] : null;
};

Accent.defaultProps = {
  className: undefined,
  color: theme.palette.common.greyLighter,
  opacity: 1,
  variant: 'variant1',
};

Accent.propTypes = {
  className: PropTypes.string,
  opacity: PropTypes.number,
  color: PropTypes.string,
  variant: PropTypes.oneOf(['variant1', 'variant2', 'variant3', 'variant4']),
};

export { Accent };
