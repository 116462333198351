import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import MaterialDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import clsx from 'clsx';
import {theme} from 'theme';
import Typography from "@material-ui/core/Typography";
import IconWrapper from "../../IconWrapper/IconWrapper";
import TimesIcon from "../../Icons/Times/TimesIcon";

const useDialogContentNativeStyles = makeStyles({
    root: {
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10),
    },
});

const useStyles = makeStyles({
    root: {
        '& .MuiDialog-paper': {
            boxShadow: theme.shadows[1],
            backgroundImage: 'url(/images/service-modal-bg.svg)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left bottom',
        },
        '& .MuiDialogTitle-root': {
            marginTop: theme.spacing(13),
            padding: '0 56px',
            paddingBottom: theme.spacing(8),
            [theme.breakpoints.down('md')]: {
                padding: '0 24px 32px 24px',
            },
        },
        '& .MuiDialogTitle-root h2': {
            fontSize: '1.5rem',
            lineHeight: '2rem',
        },
        '& .MuiDialogContent-root': {
            padding: '0 56px',
            overflow: 'hidden',
            paddingBottom: theme.spacing(18),
            fontSize: '1rem',
            lineHeight: '24px',
            position: 'relative',
            [theme.breakpoints.down('md')]: {
                padding: '0 24px 24px 24px',
            },
        },
        '& .MuiDialog-paperWidthMd': {
            maxWidth: 700,
        },
        '& .MuiDialog-paperWidthLg': {
            maxWidth: 992,
        },
    },
    modalLg: {
        width: 992,
    },
    modalMd: {
        width: 700,
    },
    modalSm: {
        width: 256,
    },
    closeButton: {
        position: 'absolute',
        top: 18,
        right: 12,
        width: 24,
        height: 24,
        minWidth: 24,
        minHeight: 24,
    },
});

const Dialog = ({handleClose, open, title, maxWidth, fullWidth, children}) => {
    const isFullWidth = fullWidth ?? false;
    const classes = useStyles();
    const dialogContentNativeClasses = useDialogContentNativeStyles();

    const onEnter = () => {
        /* As requested in SG-385, the modal needs to be scrollable within the body.
         * Due to the fact that body is scrollable too, in order to prevent the double scrollbars
         * we hide the document's scrollbars while the modal is opened.
         * SG-534 - couldn't make it work within a few hours for Alerts case. Everything seems to
         * work though when we completely stop manipulating overflow-x at html and body level.
         *  */
        // document.documentElement.style.overflow = 'hidden';
    };

    const onExited = () => {
        // document.documentElement.style.overflow = 'initial';
    };

    return (
        <MaterialDialog
            fullWidth={isFullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
            className={classes.root}
            scroll="body"
            onEnter={onEnter}
            onExited={onExited}
        >
            <DialogTitle
                disableTypography
                className={clsx(
                    maxWidth === 'lg' && classes.modalLg,
                    maxWidth === 'md' && classes.modalMd,
                    maxWidth === 'sm' && classes.modalSm,
                )}
                id="max-width-dialog-title"
            >
                <IconWrapper
                    className={classes.closeButton}
                    bgcolor="transparent"
                    bgcolorHover={theme.palette.common.white}
                    onClick={handleClose}
                >
                    <TimesIcon width={11} height={13}/>
                </IconWrapper>
                <Typography variant="h2">{title}</Typography>
            </DialogTitle>
            <DialogContent classes={dialogContentNativeClasses}>{children}</DialogContent>
        </MaterialDialog>
    );
};

export default Dialog;
