import React from 'react';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import { theme } from 'theme';
import { Button, SpacerLine } from 'components';
import { reactScrollTo } from "shared/functions/reactScrollTo";
const useStyles = makeStyles(() => ({
  root:{
    backgroundColor: theme.palette.common.bodyBg,
    backgroundImage: 'url(/images/hero-background.svg)',
    backgroundRepeat: 'no-repeat',
    minHeight: '711px',
  },
  container: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 6),
    },
  },
  accent: {
    position: 'absolute',
    top: -55,
    left: -270,
  },
  heroTextWrapper: {
    paddingTop: theme.spacing(14),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(0),
    },
  },
  heroCompanyText:{
    color: theme.palette.common.primaryYellow,
  },
  button: {
    backgroundColor: theme.palette.common.secondaryYellow,
    color: theme.palette.common.greyDark,
    borderRadius: '100px',
    padding: "12px 32px",
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    zIndex: 1,
  },
  heroImage: {
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      bottom: 45,
    },
    maxWidth: '100%',
    objectFit: 'cover',
  },
}));

const Hero = ({ ...restProps }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box className={classes.root}
         bgcolor={theme.palette.primary.main}
         pb={12}
         pt={isLgUp ? 24 : 12}
         {...restProps}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={0}>
          <Grid item xs={12} lg={8}>
            <Box
              className={classes.heroTextWrapper}
              position="relative"
              display="flex"
              justifyContent="center"
              height={1}
            >
              <div>
                <Typography variant="h1" color="textSecondary">
                  <span className={classes.heroCompanyText}>{t('HERO_TITLE_COMPANY')}</span> {t('HERO_TITLE_LINE1')}
                </Typography>
                <SpacerLine marginTop={10} marginBottom={6} />
                <Typography variant="body1" color="textSecondary">
                  {t('HERO_DESC')}
                </Typography>
                <Button 
                    variant="contained" 
                    color="secondary" 
                    className={classes.button}
                    onClick={() => reactScrollTo('contact', 1000)}
                >
                  {t('REQUEST_A_DEMO_HERO')}
                </Button>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box
              w={1}
              height={isLgUp ? 410 : 'auto'}
              position="relative"
              textAlign={isLgUp ? 'left' : 'center'}
              marginTop={isLgUp ? 0 :11}
            >
              <img alt="Financial Vision" className={classes.heroImage} src="/images/hero-image.svg" />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export { Hero };
