import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ translucent, small, ...restProps }) => (
  <img
    alt="Financial Vision"
    height={small ? 16 : 'unset'}
    src={`/images/${translucent ? 'logo-translucent.svg' : 'logo.svg'}`}
    {...restProps}
  />
);

Logo.defaultProps = {
  translucent: false,
  small: false,
};

Logo.propTypes = {
  translucent: PropTypes.bool,
  small: PropTypes.bool,
};

export { Logo };
