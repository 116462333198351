import React from 'react';
import PropTypes from 'prop-types';

import { TeamItemCard } from 'components';
import Box from '@material-ui/core/Box';

import { generateKey } from 'shared/functions/generateKey';

const TeamItem = ({ teamItemData, cardWidth }) => {
  return (
    <Box
      mb={12}
      display="flex"
      justifyContent="center"
    >
      {teamItemData.map(({ image, name, title, email, text, textShort }, index) => (
        <TeamItemCard
          key={generateKey(index, 'team_item_card')}
          image={image}
          name={name}
          title={title}
          email={email}
          text={text}
          textShort={textShort}
          width={cardWidth}
        />
      ))}
    </Box>
  );
};

TeamItem.defaultProps = {
  cardWidth: undefined,
};

TeamItem.propTypes = {
  teamItemData: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      name: PropTypes.string,
      title: PropTypes.string,
      email: PropTypes.string,
      linkedin: PropTypes.string,
    }),
  ).isRequired,
  cardWidth: PropTypes.number,
};

export { TeamItem };
