export const navTabs = [
  {
    label: 'SERVICES',
    link: 'services',
  },
  {
    label: 'ABOUT_US',
    link: 'about-us',
  },
  {
    label: 'PRICING',
    link: 'pricing',
  },
  {
    label: 'OUR_PEOPLE',
    link: 'our-team',
  },
  {
    label: 'WORK_PROCESS',
    link: 'work-process',
  },
  {
    label: 'OUR_CLIENTS',
    link: 'clients',
  },
  {
    label: 'CONTACT',
    link: 'contact',
  },
];
