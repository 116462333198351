import { withStyles } from '@material-ui/styles';
import MaterialAccordionSummary from '@material-ui/core/AccordionSummary';

import { theme } from 'theme';

const AccordionSummary = withStyles({
  root: {
    padding: theme.spacing(6.75, 8),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(6.75, 6),
    },
  },
  content: {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
})(MaterialAccordionSummary);

export { AccordionSummary };
