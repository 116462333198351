import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'theme';

const AccentVariant3 = ({ className, color, opacity }) => (
  <svg
    className={className}
    width="547"
    height="482"
    viewBox="0 0 547 482"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={opacity}
      d="M356.872 469.089L539.946 253.911C548.461 245.304 548.461 232.393 544.203 223.786L459.053 17.2143C454.795 4.30357 442.023 0 433.508 0H109.935C88.6475 0 75.8748 21.5179 84.3899 43.0357L161.026 223.786C165.283 232.393 165.283 245.304 156.768 253.911L7.75433 430.357C-9.2758 451.875 3.4968 482 29.042 482H339.842C344.099 477.696 352.614 473.393 356.872 469.089Z"
      fill={color}
    />
  </svg>
);

AccentVariant3.defaultProps = {
  className: undefined,
  color: theme.palette.common.grayishViolet,
  opacity: 0.1,
};

AccentVariant3.propTypes = {
  className: PropTypes.string,
  opacity: PropTypes.number,
  color: PropTypes.string,
};

export { AccentVariant3 };
