import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';

const useStyle = makeStyles(() => ({
  root: {
    position: 'relative',
    width: '100vw',
    height: '100vh',
  },
  center: {
    textAlign: 'center',
  },
  cover: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: ({ noBackground }) => (noBackground ? 'none' : 'rgba(255,255,255,.5)'),
    zIndex: 50,
  },
  circle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const Loader = ({ center, cover, noBackground, color, className }) => {
  const classes = useStyle({ noBackground });
  return (
    <div className={clsx(center && classes.center, cover && classes.cover, className)}>
      <CircularProgress color={color} />
    </div>
  );
};

Loader.defaultProps = {
  center: false,
  cover: false,
  noBackground: false,
  color: undefined,
  className: undefined,
};

Loader.propTypes = {
  center: PropTypes.bool,
  cover: PropTypes.bool,
  noBackground: PropTypes.bool,
  color: PropTypes.oneOf(['inherit', 'primary', 'secondary']),
  className: PropTypes.string,
};

const FullPageLoader = () => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <div className={classes.circle}>
        <Loader />
      </div>
    </div>
  );
};

export { Loader, FullPageLoader };
