import React from 'react';

const HamburgerIcon = (props) => (
    <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 11.3438H26C26.375 11.3229 26.6875 11.1875 26.9375 10.9375C27.1875 10.6875 27.3125 10.375 27.3125 10C27.3125 9.625 27.1875 9.3125 26.9375 9.0625C26.6875 8.8125 26.375 8.67708 26 8.65625H2C1.625 8.67708 1.3125 8.8125 1.0625 9.0625C0.8125 9.3125 0.6875 9.625 0.6875 10C0.6875 10.375 0.8125 10.6875 1.0625 10.9375C1.3125 11.1875 1.625 11.3229 2 11.3438ZM2 3.34375H26C26.375 3.32292 26.6875 3.1875 26.9375 2.9375C27.1875 2.6875 27.3125 2.375 27.3125 2C27.3125 1.625 27.1875 1.3125 26.9375 1.0625C26.6875 0.8125 26.375 0.677083 26 0.65625H2C1.625 0.677083 1.3125 0.8125 1.0625 1.0625C0.8125 1.3125 0.6875 1.625 0.6875 2C0.6875 2.375 0.8125 2.6875 1.0625 2.9375C1.3125 3.1875 1.625 3.32292 2 3.34375ZM2 19.3438H26C26.375 19.3229 26.6875 19.1875 26.9375 18.9375C27.1875 18.6875 27.3125 18.375 27.3125 18C27.3125 17.625 27.1875 17.3125 26.9375 17.0625C26.6875 16.8125 26.375 16.6771 26 16.6562H2C1.625 16.6771 1.3125 16.8125 1.0625 17.0625C0.8125 17.3125 0.6875 17.625 0.6875 18C0.6875 18.375 0.8125 18.6875 1.0625 18.9375C1.3125 19.1875 1.625 19.3229 2 19.3438Z" fill="#FFFFFF"/>
    </svg>

);

export { HamburgerIcon };
