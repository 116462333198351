import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {theme} from 'theme';
import Dialog from "../CustomMaterial/Dialog/Dialog";
import clsx from "clsx";
const useStyles = makeStyles({
    title: {
        marginBottom: theme.spacing(4),
        whiteSpace: 'pre-line',
    },
    description: {
        [theme.breakpoints.up('lg')]: {
            whiteSpace: 'pre-line',
        },
    },
    link: {
        cursor: 'pointer',
        color: theme.palette.common.secondaryBlue,
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'none',
        },
        fontWeight: 400
    },
    accent: {
        position: 'absolute',
        top: -55,
        left: -270,
    },
    dialogFooter:{
        paddingBottom: theme.spacing(4)
    }
});

const Feature = ({icon, title,modalTitle, modalTitleSm, description, boxProps, iconProps}) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const [showServiceDialog, setShowServiceDialog] = useState(false);

    const toggleShowServiceDialog = async () => {
        setShowServiceDialog(!showServiceDialog);
    };

    return (
        <Box display="flex" justifyContent="center" flexDirection="column" {...boxProps}>
            <Dialog
                title={!isMdDown ? t(modalTitle) : t(modalTitleSm)}
                maxWidth={isMdDown ? 'sm' : 'md'}
                open={showServiceDialog}
                handleClose={toggleShowServiceDialog}
            >
                <div className={clsx(isMdDown && classes.dialogFooter)}>
                               {t(description)}
                </div>
            </Dialog>
            <Box height={isLgUp ? 105 : undefined} display="flex" justifyContent="center" mb={7}>
                <img alt="Financial Vision" src={icon} {...iconProps} />
            </Box>
            <Typography className={classes.title} variant="h5" align="center">
                {t(title)}
            </Typography>
            <Typography variant="h5" align="center" className={classes.link} onClick={toggleShowServiceDialog}>
                {t('READ_MORE')}
            </Typography>
        </Box>
    );
};

Feature.defaultProps = {
    boxProps: {},
    iconProps: {},
};

Feature.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    boxProps: PropTypes.object,
    iconProps: PropTypes.object,
};

export {Feature};
