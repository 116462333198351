import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'theme';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { Button } from 'components';

const useStyles = makeStyles(() => ({
  text: {
    marginBottom: theme.spacing(12),
    color: theme.palette.common.white,
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

const InfoBox = ({ text, color, actionButton, accentImg, className, boxProps }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { label: actionButtonLabel, action } = actionButton || {};

  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      position="relative"
      bgcolor={color}
      className={className}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      px={isLgUp ? 28.75 : 6}
      py={isLgUp ? 32 : 20}
      borderRadius={isLgUp ? 32 : 0}
      {...boxProps}
    >
      <Typography variant="h2" className={classes.text}>
        {t(text)}
      </Typography>
      {actionButton && (
        <Button className={classes.button} variant="contained" color="secondary" onClick={action}>
          {t(actionButtonLabel)}
        </Button>
      )}
      {accentImg && (
        <Box
          position="absolute"
          bottom={isLgUp ? 30 : 'unset'}
          right={isLgUp ? 30 : 10}
          top={isLgUp ? 'unset' : 20}
        >
          <img alt="info-box-icon" src={accentImg} width={isLgUp ? 'unset' : 210} />
        </Box>
      )}
    </Box>
  );
};

InfoBox.defaultProps = {
  className: undefined,
  color: theme.palette.primary.main,
  actionButton: undefined,
  accentImg: undefined,
  boxProps: {},
};

InfoBox.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  actionButton: PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  }),
  accentImg: PropTypes.string,
  boxProps: PropTypes.object,
};

export { InfoBox };
