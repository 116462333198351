import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import MaterialButton from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  colorInvert: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.primaryBlue,
    },
  },
  primary: {
    '&:hover': {
      backgroundColor: theme.palette.common.primaryBlue,
    },
  },
  secondary: {
    '&:hover': {
      backgroundColor: theme.palette.common.veryLightYellow,
    },
  },
}));

const Button = ({ children, color, onClick, className, ...props }) => {
  const classes = useStyles();
  const colorInvert = color === 'invert';
  const colorPrimary = color === 'primary';
  const colorSecondary = color === 'secondary';

  return (
    <MaterialButton
      onClick={onClick}
      color={colorInvert ? 'default' : color}
      className={clsx(
        colorInvert && classes.colorInvert,
        colorPrimary && classes.primary,
        colorSecondary && classes.secondary,
        className,
      )}
      {...props}
    >
      {children}
    </MaterialButton>
  );
};

Button.defaultProps = {
  className: undefined,
  color: 'default',
  onClick: () => null,
};

Button.propTypes = {
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'inherit', 'invert']),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export { Button };
