import React from 'react';
import { lazy } from 'react';

const Main = lazy(() => import('./scenes/Main/Main'));
const NotFound = lazy(() => import('./scenes/NotFound/NotFound'));

const routes = [
  {
    name: 'main',
    path: [
      '/',
      '/hero',
      '/services',
      '/about-us',
      '/pricing',
      '/our-team',
      '/clients',
      '/work-process',
      '/contact',
      '/raamatupidamisteenuse-kampaania/'
    ],
    exact: true,
    component: ({ props, viewLoadedCallback }) => (
      <Main viewLoadedCallback={viewLoadedCallback} {...props} />
    ),
  },
  {
    name: 'not-found',
    path: '/not-found',
    component: NotFound,
  },
];

export { routes };
