import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import { makeStyles } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import { generateKey } from 'shared/functions/generateKey';

const indicatorStyle = {
  height: 5,
  borderRadius: 5,

};

const useStyles = makeStyles((theme) => ({
  indicator: {
    height: indicatorStyle.height,
    borderRadius: indicatorStyle.borderRadius,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 52,
      width: "100%",
      borderRadius: indicatorStyle.borderRadius,
      backgroundColor: theme.palette.common.primaryYellow,
    }
  },
  tab: {
    minWidth: 110,
    padding: theme.spacing(2, 4),
    fontSize: "1.125rem",
    fontWeight: 400,
    textTransform: 'none',
    opacity: 1,
    position: 'relative',
    '&:hover': {
      '&:after': {
        content: '""',
        position: 'absolute',
        width: '52px',
        bottom: 0,
        height: indicatorStyle.height,
        borderRadius: indicatorStyle.borderRadius,
        backgroundColor: theme.palette.common.primaryYellow,
      },
    },
    [theme.breakpoints.down('lg')]: {
      minWidth: 70,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
      padding: theme.spacing(2, 3),
    },
  },
  selected: {
    fontWeight: 800,
  },
  scroller: {
    height: 48,
    display: 'flex',
    marginLeft: theme.spacing(22)
  },
}));

const useTabLinkStyles = makeStyles((theme) => ({
  tabLink: {
    position: 'relative',
  },
  extraData: {
    backgroundColor: theme.palette.common.vividYellow,
    fontSize: '0.75rem',
    lineHeight: '16px',
    color: theme.palette.common.veryDarkGray,
  },
}));

const TabLink = React.forwardRef(
  ({ to, children, extraData, useScrollLink, tab, activeTabChanged, ...restProps }, ref) => {
    const classes = useTabLinkStyles();

    const content = (
      <>
        {children}
        {extraData && (
          <Box
            width={16}
            height={16}
            className={classes.extraData}
            position="absolute"
            right={7}
            bottom="50%"
            borderRadius="50%"
          >
            {extraData}
          </Box>
        )}
      </>
    );

    return useScrollLink ? (
      <ScrollLink
        ref={ref}
        to={to}
        spy
        smooth
        offset={0}
        duration={500}
        onSetActive={() => activeTabChanged(tab, true)}
        onSetInactive={() => activeTabChanged(tab, false)}
        className={classes.tabLink}
        {...restProps}
      >
        {content}
      </ScrollLink>
    ) : (
      <Link to={to} className={classes.tabLink} {...restProps}>
        {content}
      </Link>
    );
  },
);

TabLink.defaultProps = {
  extraData: undefined,
  useScrollLink: false,
  tab: undefined,
  activeTabChanged: () => null,
};

TabLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  extraData: PropTypes.any,
  useScrollLink: PropTypes.bool,
  activeTabChanged: PropTypes.func,
  tab: PropTypes.shape({ label: PropTypes.string, link: PropTypes.string }),
};

const NavTabs = ({ tabs, activeTab, activeTabChanged, className, useScrollLink }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(false);

  useEffect(() => {
    if (activeTab) {
      setSelectedTab(activeTab);
    } else {
      setSelectedTab(false);
    }
  }, [activeTab]);

  const onTabChange = (_, value) => {
    setSelectedTab(value);
  };

  return (
    <Tabs
      value={selectedTab}
      TabIndicatorProps={{children: <span />}}
      classes={{ scroller: classes.scroller, indicator: classes.indicator }}
      className={className}
      onChange={onTabChange}
      aria-label="navigation"
    >
      {tabs.map(({ label, link, extraData }, index) => (
        <Tab
          key={generateKey(index, link)}
          value={tabs[index]}
          label={t(label)}
          to={link}
          component={TabLink}
          activeTabChanged={activeTabChanged}
          classes={{ root: classes.tab, selected: classes.selected }}
          tab={tabs[index]}
          extraData={extraData}
          useScrollLink={useScrollLink}
          disableRipple
        />
      ))}
    </Tabs>
  );
};

NavTabs.defaultProps = {
  activeTab: undefined,
  className: undefined,
  useScrollLink: false,
  activeTabChanged: () => null,
};

NavTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ).isRequired,
  activeTab: PropTypes.shape({
    label: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
  className: PropTypes.string,
  useScrollLink: PropTypes.bool,
  activeTabChanged: PropTypes.func,
};

export { NavTabs };
